import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";



//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region slider
$(function () {
  $(".slider").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
  });
});
//#endregion
